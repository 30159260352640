import React from "react"

const InstagramLogo = () => (
    <svg
        className="footer__social-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
    >
        <path d="M16 2.88c4.28 0 4.78.02 6.46.1 1.56.06 2.4.33 2.97.54.75.3 1.28.64 1.84 1.2.56.56.9 1.1 1.2 1.84.22.56.48 1.4.55 2.97.07 1.68.1 2.19.1 6.46s-.03 4.78-.1 6.46a8.77 8.77 0 0 1-.55 2.97 4.94 4.94 0 0 1-1.2 1.84c-.56.56-1.09.9-1.83 1.2-.57.22-1.42.48-2.97.55-1.69.07-2.2.1-6.46.1s-4.79-.03-6.47-.1a8.77 8.77 0 0 1-2.97-.55 4.95 4.95 0 0 1-1.84-1.2c-.56-.56-.9-1.09-1.2-1.83a8.81 8.81 0 0 1-.55-2.97c-.07-1.69-.09-2.2-.09-6.46s.02-4.79.1-6.47c.06-1.56.33-2.4.54-2.97.3-.74.64-1.28 1.2-1.83.56-.57 1.1-.91 1.84-1.2a8.8 8.8 0 0 1 2.97-.55c1.68-.08 2.18-.1 6.46-.1zM16 0c-4.34 0-4.89.02-6.6.1-1.7.07-2.86.34-3.88.74A7.8 7.8 0 0 0 2.7 2.69 7.83 7.83 0 0 0 .84 5.52C.44 6.54.17 7.7.09 9.4.02 11.11 0 11.66 0 16s.02 4.89.1 6.6c.07 1.7.34 2.86.74 3.87a7.8 7.8 0 0 0 1.85 2.84 7.82 7.82 0 0 0 2.83 1.85c1.02.39 2.18.67 3.88.74 1.7.08 2.25.1 6.6.1s4.88-.02 6.59-.1c1.7-.07 2.87-.35 3.88-.74 1.05-.41 1.94-.96 2.83-1.85s1.44-1.78 1.84-2.83c.4-1.02.67-2.18.75-3.88.07-1.7.1-2.25.1-6.6s-.03-4.88-.1-6.59c-.08-1.7-.35-2.87-.75-3.88a7.5 7.5 0 0 0-1.83-2.84A7.82 7.82 0 0 0 26.48.84C25.46.44 24.3.17 22.6.1 20.9.02 20.35 0 16 0z" />
        <path d="M16 7.78a8.22 8.22 0 1 0 0 16.44 8.22 8.22 0 0 0 0-16.44zm0 13.55a5.33 5.33 0 1 1 0-10.66 5.33 5.33 0 0 1 0 10.66zM26.46 7.46a1.92 1.92 0 1 1-3.84 0 1.92 1.92 0 0 1 3.84 0z" />
    </svg>
)

const YouTubeLogo = () => (
    <svg
        className="footer__social-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
    >
        <path d="M16.348 2.652c-3.004-.203-9.696-.203-12.696 0C.406 2.875.023 4.836 0 10c.023 5.152.402 7.125 3.652 7.348 3 .203 9.692.203 12.696 0C19.594 17.125 19.977 15.164 20 10c-.023-5.152-.402-7.125-3.652-7.348zM7.5 13.332V6.668l6.668 3.328zm0 0" />
    </svg>
)

const FacebookLogo = () => (
    <svg
        className="footer__social-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
    >
        <path d="M19 6h5V0h-5a7 7 0 0 0-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.54.46-1 1-1z" />
    </svg>
)

const TwitterLogo = () => (
    <svg
        className="footer__social-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
    >
        <path d="M20 3.797a8.092 8.092 0 01-2.355.644 4.1 4.1 0 001.804-2.27 8.145 8.145 0 01-2.61.997 4.088 4.088 0 00-2.991-1.293c-2.649 0-4.598 2.469-4 5.035A11.67 11.67 0 011.39 2.625a4.11 4.11 0 001.27 5.477A4.03 4.03 0 01.804 7.59c-.047 1.898 1.316 3.68 3.289 4.074a4.087 4.087 0 01-1.852.07 4.1 4.1 0 003.832 2.848A8.248 8.248 0 010 16.285a11.613 11.613 0 006.29 1.84c7.62 0 11.92-6.434 11.663-12.203.8-.578 1.496-1.3 2.047-2.125zm0 0" />
    </svg>
)

export const RenderSocialIcon = type => {
    if (type === "facebook") {
        return (
            <>
                <span className="screen-reader-text">
                    Follow us on Facebook
                </span>
                {FacebookLogo()}
            </>
        )
    }
    if (type === "instagram") {
        return (
            <>
                <span className="screen-reader-text">
                    Follow us on Instagram
                </span>
                {InstagramLogo()}
            </>
        )
    }
    if (type === "youtube") {
        return (
            <>
                <span className="screen-reader-text">Subscribe on YouTube</span>
                {YouTubeLogo()}
            </>
        )
    }
    if (type === "twitter") {
        return (
            <>
                <span className="screen-reader-text">Follow us on Twitter</span>
                {TwitterLogo()}
            </>
        )
    }
}
