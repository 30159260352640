import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const NavItem = ({ link, linkClass, resetScroll, pageID }) => {
    const activeClass =
        link.object_id === pageID
            ? `${linkClass} ${linkClass}--active`
            : `${linkClass}`

    if (typeof window !== "undefined") {
        if (link.object === "custom") {
            return (
                <a
                    href={link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    className={linkClass}
                >
                    {link.title}
                </a>
            )
        }
    }

    return (
        <Link
            onClick={resetScroll}
            className={activeClass}
            to={`/${link.object_slug}`}
            key={link.object_id}
        >
            {link.title}
        </Link>
    )
}

NavItem.propTypes = {
    link: PropTypes.object,
    linkClass: PropTypes.string,
    resetScroll: PropTypes.func,
    pageID: PropTypes.any,
}

export default NavItem
