import React, { useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import Footer from "./Footer"

import "./css/style.scss"

const Layout = ({ children }) => {
    const catchSubmit = useCallback(() => {
        const enforceFormat = event => {
            // Input must be of a valid number format or a modifier key, and not longer than ten digits
            if (!isNumericInput(event) && !isModifierKey(event)) {
                event.preventDefault()
            }
        }

        const formatToPhone = event => {
            if (isModifierKey(event)) {
                return
            }

            // I am lazy and don't like to type things more than once
            const target = event.target
            const input = target.value.replace(/\D/g, "").substring(0, 10) // First ten digits of input only
            const zip = input.substring(0, 3)
            const middle = input.substring(3, 6)
            const last = input.substring(6, 10)

            if (input.length > 6) {
                target.value = `(${zip}) ${middle} - ${last}`
            } else if (input.length > 3) {
                target.value = `(${zip}) ${middle}`
            } else if (input.length > 0) {
                target.value = `(${zip}`
            }
        }

        if (typeof document !== "undefined") {
            let btn = document.querySelector(".wpcf7-submit")
            if (btn) {
                btn.addEventListener("click", preventSubmission, false)
            }
            const inputElement = document.querySelector(".number-mask")
            if (inputElement) {
                inputElement.setAttribute("inputmode", "tel")
                inputElement.addEventListener("keydown", enforceFormat)
                inputElement.addEventListener("keyup", formatToPhone)
            }
        }
    }, [])

    const removeEvent = useCallback(() => {
        if (typeof document !== "undefined") {
            let btn = document.querySelector(".wpcf7-submit")
            if (btn) {
                btn.removeEventListener("click", preventSubmission)
            }
        }
    }, [])

    const isModifierKey = event => {
        const key = event.keyCode
        return (
            event.shiftKey === true ||
            key === 35 ||
            key === 36 || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            // Allow Ctrl/Command + A,C,V,X,Z
            ((event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 ||
                    key === 67 ||
                    key === 86 ||
                    key === 88 ||
                    key === 90))
        )
    }

    const preventSubmission = e => {
        e.preventDefault()
        let formID = document.querySelectorAll(
            ".wpcf7-form input[type=hidden]"
        )[0].value
        let data = {}

        const createFormData = () => {
            e.target.form
                .querySelectorAll(".wpcf7-form-control:not(.wpcf7-submit")
                .forEach(x => {
                    data[x.getAttribute("name")] = x.value
                })
        }
        createFormData()

        const getFormData = object => {
            const formData = new FormData()
            Object.keys(object).forEach(key =>
                formData.append(key, object[key])
            )
            return formData
        }

        axios
            .post(
                `https://admin.yujoindy.com/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`,
                getFormData(data),
                {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then(function(response) {
                let outputElm = e.target.form.querySelector(
                    ".wpcf7-response-output"
                )

                if (response.data.status === "mail_sent") {
                    outputElm.classList =
                        "wpcf7-response-output wpcf7-response-output--success"
                    outputElm.innerText = response.data.message

                    e.target.form
                        .querySelectorAll(
                            ".wpcf7-form-control:not(.wpcf7-submit"
                        )
                        .forEach(x => {
                            x.value = ""
                        })

                    setTimeout(() => {
                        outputElm.innerText = ""
                    }, 1500)

                    setTimeout(() => {
                        outputElm.classList = "wpcf7-response-output"
                    }, 1400)

                    e.target.form
                        .querySelectorAll(
                            ".wpcf7-form-control[aria-required=true]"
                        )
                        .forEach(x => {
                            x.classList.remove("wpcf7-form-control--error")
                        })
                } else {
                    outputElm.classList.add("wpcf7-response-output--error")
                    outputElm.innerText = response.data.message

                    response.data.invalidFields.forEach(x => {
                        document.querySelector(`${x.into} input`) &&
                            document
                                .querySelector(`${x.into} input`)
                                .classList.add("wpcf7-form-control--error")

                        document.querySelector(`${x.into} select`) &&
                            document
                                .querySelector(`${x.into} select`)
                                .classList.add("wpcf7-form-control--error")
                    })
                }
            })
            .catch(function(error) {
                console.log(error)
            })
    }

    useEffect(() => {
        catchSubmit()
        let selectField = document.querySelectorAll(".wpcf7-select")
        if (selectField) {
            selectField.forEach(x =>
                x.parentElement.classList.add("wpcf7-form-control--select")
            )
        }
        return () => {
            removeEvent()
        }
    }, [catchSubmit, removeEvent])

    const isNumericInput = event => {
        const key = event.keyCode
        return (
            (key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        )
    }

    return (
        <>
            <main>{children}</main>
            <Footer />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
