import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from "../images/favicon.png"

function SEO({ lang, meta, title, description, image }) {
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={title}
            link={[
                {
                    rel: "shortcut icon",
                    type: "image/x-icon",
                    href: `${favicon}`,
                },
                {
                    rel: "preload",
                    as: "font",
                    type: "./fonts/roboto-condensed-v18-latin-700.woff2",
                    crossOrigin: true,
                },
                {
                    rel: "preload",
                    as: "font",
                    type: "./fonts/roboto-condensed-v18-latin-regular.woff2",
                    crossOrigin: true,
                },
            ]}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: `Yujo Indy`,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: image,
                },
                {
                    name: `twitter:card`,
                    content: description,
                },
                {
                    name: `twitter:creator`,
                    content: `@Yujo_Indy`,
                },
                {
                    name: `twitter:title`,
                    content: `Yujo Indy`,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
            ].concat(meta)}
        ></Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    link: [],
    image: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    image: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    script: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    link: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
