import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import NavItem from "./NavItem"
import { RenderSocialIcon } from "./SocialLogos"
import GrubhubBtn from "../images/grubhub.svg"
import DoorDashBtn from "../images/door-dash.svg"
export default class Footer extends Component {
    closeModal() {
        const modal = document.getElementById("delivery-modal")
        modal.classList.remove("delivery-modal--open")
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        wordpressWpApiMenusMenusItems(
                            name: { eq: "Footer Menu" }
                        ) {
                            name
                            items {
                                object_id
                                object_slug
                                title
                                type
                                url
                            }
                        }
                        wordpressAcfOptions {
                            options {
                                toast
                                deliveryService {
                                    serviceName
                                    serviceUrl
                                }
                                orderSteps {
                                    stepTitle
                                    stepContent
                                }
                                socialMedia {
                                    name
                                    url
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    let {
                        deliveryService,
                        socialMedia,
                    } = data.wordpressAcfOptions.options
                    const { items } = data.wordpressWpApiMenusMenusItems
                    return (
                        <>
                            <div id="delivery-modal" className="delivery-modal">
                                <div className="delivery-modal__container">
                                    <h4 className="delivery-modal__header">
                                        Delivery Options
                                    </h4>
                                    <button
                                        onClick={this.closeModal}
                                        type="button"
                                        className="delivery-modal__close"
                                    >
                                        <span>Menu</span>
                                    </button>
                                    {deliveryService &&
                                        deliveryService.map(item => {
                                            let formatedServiceName = item.serviceName
                                                .trim()
                                                .toLowerCase()
                                            if (
                                                formatedServiceName ===
                                                "grubhub"
                                            ) {
                                                return (
                                                    <a
                                                        href={item.serviceUrl}
                                                        key={item.serviceUrl}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        className=""
                                                    >
                                                        <img
                                                            className="cta-btn--image"
                                                            src={GrubhubBtn}
                                                            alt="Grubhub Order Button"
                                                        />
                                                    </a>
                                                )
                                            } else if (
                                                formatedServiceName ===
                                                "doordash"
                                            ) {
                                                return (
                                                    <a
                                                        href={item.serviceUrl}
                                                        key={item.serviceUrl}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        className=""
                                                    >
                                                        <img
                                                            className="cta-btn--image"
                                                            src={DoorDashBtn}
                                                            alt="Grubhub Order Button"
                                                        />
                                                    </a>
                                                )
                                            }
                                        })}
                                </div>
                            </div>
                            <div className="footer">
                                <svg
                                    preserveAspectRatio="none"
                                    className="footer__wave"
                                    viewBox="0 0 1440 36"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 15.63c86.77 6 195.55 9 326.36 9 196.2 0 708.76-23.3 935.98-24.63 51.46 0 110.68 1.26 177.66 3.79V36H0V15.63z"
                                        fill="#222"
                                    />
                                </svg>
                                <div className="site-width">
                                    <div className="footer__copy">
                                        <address>
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href="https://www.google.com/maps/place/9431+N+Meridian+St,+Indianapolis,+IN+46260/@39.9251094,-86.158228,17z/data=!3m1!4b1!4m5!3m4!1s0x8814acb635e18a6d:0xed1d69075a779a2f!8m2!3d39.9251094!4d-86.1560393"
                                            >
                                                9431 N Meridian St,
                                                Indianapolis, IN 46260
                                            </a>
                                        </address>
                                        <span>
                                            &copy; {new Date().getFullYear()}{" "}
                                            YUJO &mdash;{" "}
                                        </span>
                                        <a
                                            className="footer__contact-cta"
                                            href="tel:317-669-0315"
                                        >
                                            317.669.0315
                                        </a>
                                    </div>
                                    <div className="footer__menu">
                                        <div>
                                            {items.map(item => (
                                                <NavItem
                                                    key={item.object_id}
                                                    link={item}
                                                    linkClass="nav-item"
                                                />
                                            ))}
                                        </div>
                                        <div className="footer__social">
                                            {socialMedia &&
                                                socialMedia.map(social => {
                                                    let formatedSocial = social.name
                                                        .trim()
                                                        .toLowerCase()

                                                    return (
                                                        <a
                                                            key={social.name}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={social.url}
                                                        >
                                                            {RenderSocialIcon(
                                                                formatedSocial
                                                            )}
                                                        </a>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }}
            />
        )
    }
}
